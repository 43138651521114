/**
 *
 *  Button
 *
 **/
.mod--button__list
    text-align center

.mod--button__list__item
    background $colorDefault
    border-radius 5px
    display inline-block
    font-size 15px
    margin 0 1em
    width 220px
    a
        border-radius 5px
        color #fff
        display block
        line-height 1
        // padding 14px 0
        text-align center
        &:hover
            background-color lightness($colorDefault, 40%)
            color #fff
            text-decoration none
        &:visited
            color #fff
        .fa
            font-size 16px
            margin 0 3px 0 0
            padding 15px 0 16px
            vertical-align middle
            + span
              vertical-align middle
        .fa-phone
            font-size 20px
            padding 14px 0
            + span
              font-size 18px
    @media(max-width: 980px)
        display block
        margin 1em auto

.beauty
    .mod--button__list__item
        background-color $colorBeauty
        a
            &:hover
                background-color lightness($colorBeauty, 40%)
.health
    .mod--button__list__item
        background-color $colorHealth
        a
            &:hover
                background-color lightness($colorHealth, 40%)
.gourmet
    .mod--button__list__item
        background-color $colorGourmet
        a
            &:hover
                background-color lightness($colorGourmet, 40%)
.shopping
    .mod--button__list__item
        background-color $colorShopping
        a
            &:hover
                background-color lightness($colorShopping, 40%)
.lesson
    .mod--button__list__item
        background-color $colorLesson
        a
            &:hover
                background-color lightness($colorLesson, 40%)
.life
    .mod--button__list__item
        background-color $colorLife
        a
            &:hover
                background-color lightness($colorLife, 40%)
.leisure
    .mod--button__list__item
        background-color $colorLeisure
        a
            &:hover
                background-color lightness($colorLeisure, 40%)
.pet
    .mod--button__list__item
        background-color $colorPet
        a
            &:hover
                background-color lightness($colorPet, 40%)
.service
    .mod--button__list__item
        background-color $colorService
        a
            &:hover
                background-color lightness($colorService, 40%)

.mod--button__list__item--tel
    a
        background-color #bf0000 !important
        &:hover
            background-color #cd5c5c !important
    span
        letter-spacing .1em

.mod--button__list__item--mail
    a
        background-color #bf0000 !important
        &:hover
            background-color #cd5c5c !important

.mod--button__list__item--submit
    a
        background-color #bf0000 !important
        &:hover
            background-color #cd5c5c !important

.formBtn
    background-color #bf0000 !important
    &:hover
        background-color #cd5c5c !important

.mod--button__block
    background $colorDefault
    border-radius 5px
    font-size 15px
    margin 1em 0
    text-align center
    a
        border-radius 5px
        color #fff
        display block
        line-height 1
        padding 16px 0
        text-align center
        &:hover
            background-color lightness($colorDefault, 40%)
            color #fff
            text-decoration none
        &:visited
            color #fff
        .fa
            font-size 16px
            margin 0 3px 0 0

.beauty
    .mod--button__block
        background-color $colorBeauty
        a
            &:hover
                background-color lightness($colorBeauty, 40%)
.health
    .mod--button__block
        background-color $colorHealth
        a
            &:hover
                background-color lightness($colorHealth, 40%)
.gourmet
    .mod--button__block
        background-color $colorGourmet
        a
            &:hover
                background-color lightness($colorGourmet, 40%)
.shopping
    .mod--button__block
        background-color $colorShopping
        a
            &:hover
                background-color lightness($colorShopping, 40%)
.lesson
    .mod--button__block
        background-color $colorLesson
        a
            &:hover
                background-color lightness($colorLesson, 40%)
.life
    .mod--button__block
        background-color $colorLife
        a
            &:hover
                background-color lightness($colorLife, 40%)
.leisure
    .mod--button__block
        background-color $colorLeisure
        a
            &:hover
                background-color lightness($colorLeisure, 40%)
.pet
    .mod--button__block
        background-color $colorPet
        a
            &:hover
                background-color lightness($colorPet, 40%)
.service
    .mod--button__block
        background-color $colorService
        a
            &:hover
                background-color lightness($colorService, 40%)

.mod--button__list--side
    .mod--button__list__item
        display block
        margin 1em auto

.mod--link--attention
    font-weight 700
