/**
 *
 *  Menu
 *
 **/
.mod--menu__title
    background $colorRed
    color #fff
    font-size 22px
    font-weight 700
    padding .2em .5em
    @media(max-width: 980px)
      font-size 16px

.mod--menu__inner
    border 5px solid $colorRed
    border-width 0 5px 5px
    padding .5em

.mod--menu__name
    font-size 22px
    @media(max-width: 980px)
      font-size 16px

