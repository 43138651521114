/**
 *
 *  Link
 *
 **/
.mod--link__icon
    display table
    dt
        display table-cell
        .fa
            color #646464
            // font-size 18px
            margin 2px 0 0
    dd
        display table-cell


.mod--link__list
    list-style none

.mod--link__list__item
    border-bottom 2px solid #e4e4e4
    a
        display block
        padding 1em 0
        &:hover
            dd
                text-decoration underline

.icon-holiday
    background url("http://cdn.notices.jp/lekumo/2015/images/common/icon_holiday.jpg") no-repeat center 5px
    background-size 14px
    display block
    width 14px
    height 14px
