/**
 *
 *  Header
 *
 **/
.header__wrapper
    background $colorDefault
    color #fff
    min-width 1024px
    padding 16px 0
    // position fixed
    // top 0
    width 100%
    height 96px - 16px -16px
    z-index 11
    .container
        display table
    @media(max-width: 980px)
        box-shadow 0px 3px 5px 0px rgba(0,0,0,0.75)
        min-width 0
        padding 0
        height 44px

.beauty
    .header__wrapper
        background-color $colorBeauty
.health
    .header__wrapper
        background-color $colorHealth
.gourmet
    .header__wrapper
        background-color $colorGourmet
.shopping
    .header__wrapper
        background-color $colorShopping
.lesson
    .header__wrapper
        background-color $colorLesson
.life
    .header__wrapper
        background-color $colorLife
.leisure
    .header__wrapper
        background-color $colorLeisure
.pet
    .header__wrapper
        background-color $colorPet
.service
    .header__wrapper
        background-color $colorService

.header__title
    display table-cell
    width (( 680 / 980 ) * 100)%
    @media(max-width: 980px)
        padding 0 .5em
        vertical-align middle
        wiidth auto

.header__lead
    font-size 14px
    max-height 1.6em
    overflow hidden
    @media(max-width: 980px)
        font-size 7px

.header__name
    font-size 26px
    font-weight 700
    max-height 1.6em
    overflow hidden
    a
        color #fff
        &:visited
            color #fff
    @media(max-width: 980px)
        font-size 13px

.header__caption
    display none
    @media(max-width: 980px)
        display block
        font-size 7px
        white-space nowrap

.header__tel
    display table-cell
    font-size 28px
    font-weight 700
    padding .6em 0 0
    text-align right
    vertical-align bottom
    width (( 300 / 980 ) * 100)%
    .fa
        font-size 38px
        vertical-align middle
    @media(max-width: 980px)
        border-left 2px solid #fff
        box-sizing border-box
        font-size 16px
        padding .3em 0
        text-align center
        width (( 88 / 640 ) * 100)%
        height 44px
        .fa
            font-size 19px
        a
          color #fff
          &:visited
          &:hover
            color #fff
            text-decoration none

.header__tel__number
    @media(max-width: 980px)
        display none

.header__menu
    display none
    @media(max-width: 980px)
        border-left 2px solid #fff
        box-sizing border-box
        display table-cell
        padding .3em 0
        text-align center
        width (( 88 / 640 ) * 100)%
        height 44px
        .fa
          font-size 19px
          margin 3px 0 0

.global-navigation__title
    display none
    @media(max-width: 980px)
        background $colorDefault
        color #fff
        display block
        line-height 44px
        padding 0 .8em
        position relative
        vertical-align middle
        i
            cursor pointer
            font-size 20px
            position absolute
            top 50%
            right 2%
            margin-top -10px

.beauty
    .global-navigation__title
        background-color $colorBeauty
.health
    .global-navigation__title
        background-color $colorHealth
.gourmet
    .global-navigation__title
        background-color $colorGourmet
.shopping
    .global-navigation__title
        background-color $colorShopping
.lesson
    .global-navigation__title
        background-color $colorLesson
.life
    .global-navigation__title
        background-color $colorLife
.leisure
    .global-navigation__title
        background-color $colorLeisure
.pet
    .global-navigation__title
        background-color $colorPet
.service
    .global-navigation__title
        background-color $colorService
