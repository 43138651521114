/**
 *
 *  Google Map
 *
 **/
.googlemap
    position relative
    iframe
        width 100% !important
        // pointer-events none !important

#map
    height 300px

.google__map__screen
    // display none
    display block
    width 100%
    height 300px
    position absolute
    top 0
    // @media(max-width: 980px)
    //     display block
    //     width 100%
    //     height 300px
    //     position absolute
    //     top 0


.googlecalendar
    width 100%
    height 356px
    overflow scroll
