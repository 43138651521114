/**
 *
 *  Images
 *
 **/
.mod--images
    clearfix()
    margin 1em 0
    > img
        float left
        margin 0 1em .5em 0
    img
        max-width 100%

.mod--images__full
    img
        width 100% !important
        height auto !important

.mod--images__left
    float left
    margin 0 1em .5em 0
    width 30%
    img
        width 100%

.mod--images__left50
    float left
    margin 0 1em .5em 0
    width 50%
    img
        width 100%

.mod--images__right
    float right
    width 65%

.mod--images__left--half
    float left
    width 49%
    img
        width 100%

.mod--images__right--half
    float right
    width 49%
    img
        width 100%


.mod-images__half--title
  max-height 3em
  overflow hidden

.mod--images__first
    img
        max-width 100%

.mod-images__line
    img
        max-width 100%
