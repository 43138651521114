/**
 *
 *  Category
 *
 **/
.pager-inner
  position relative
  height 50px

.pager-left
  position absolute
  top 10px
  left 30px

.pager-right
  position absolute
  top 10px
  right 30px
