/**
 *
 *  First Area
 *
 */
.mod__catchArea__title
    background-color $colorDefault
    color #fff
    font-size 36px
    margin-bottom 10px
    text-align center
    @media(max-width: 980px)
        font-size 18px

.mod--catchArea__message
    margin-bottom 10px
    text-align center

.mod__catchArea__text
    font-size 32px
    line-height 1.4
    @media(max-width: 980px)
        font-size 18px

.mod__catchArea__text2
    font-size 22px
    line-height 1.4
    @media(max-width: 980px)
        font-size 14px

.mod__catchArea__image
    border 2px solid $colorDefault
    max-width 100%
    margin-bottom 20px
    padding 4px
    img
        width 100%

.mod__catchArea__headline
    background-color $colorDefault
    color #fff
    font-size 24px
    font-weight 500
    margin-bottom 10px
    text-align center
    @media(max-width: 980px)
        font-size 18px

.mod__catchArea__list
    .mod--checkbox__icon
        margin-bottom 0
        @media(max-width: 980px)
            font-size 12px
            .mod--checkbox__icom--image
                background-size 18px
                width 18px
                height 18px


/**
 *
 * Color
 *
 */
.beauty
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorBeauty
    .mod__catchArea__image
        border-color $colorBeauty
.health
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorHealth
    .mod__catchArea__image
        border-color $colorHealth
.gourmet
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorGourmet
    .mod__catchArea__image
        border-color $colorGourmet
.shopping
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorShopping
    .mod__catchArea__image
        border-color $colorShopping
.lesson
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorLesson
    .mod__catchArea__image
        border-color $colorLesson
.life
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorLife
    .mod__catchArea__image
        border-color $colorLife
.leisure
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorLeisure
    .mod__catchArea__image
        border-color $colorLeisure
.pet
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorPet
    .mod__catchArea__image
        border-color $colorPet
.service
    .mod__catchArea__title
    .mod__catchArea__headline
        background-color $colorService
    .mod__catchArea__image
        border-color $colorService
