.individual__body
    clearfix()
    p
        margin 1em 0
    img
        max-width 100%
        height auto !important

.module__article__sns
    padding 1em

.module__article__sns__list
    clearfix()

.module__article__sns__item
.module__article__sns__item--tw
.module__article__sns__item--pocket
    display inline-block
    margin-right 10px
    vertical-align top

.module__article__sns__item--tw
    width 75px

.module__article__sns__item--pocket
    width 60px

.tweet__baloon
    border 1px solid #bbb
    border-radius 2px
    color #4A5665
    font-size 11px
    line-height 13px
    margin-bottom 8px
    position relative
    text-align center
    z-index 0
    a
        color #4A5665
        display block
        padding 10px 0 8px
        text-decoration none
        &:hover
            color #4A5665
            text-decoration none
    &:before
        border-color #fff transparent transparent transparent
        border-style solid
        border-width 4px 4px 0 4px
        content ''
        margin-left -4px
        position absolute
        bottom -3px
        left 50%
        width 0
        height 0
        z-index 0
    &:after
        border-color #bbb transparent transparent transparent
        border-style solid
        border-width 5px 5px 0 5px
        content ''
        margin-left -5px
        position absolute
        bottom -5px
        left 50%
        width 0
        height 0
        z-index -1



/**
 *  Article Number
 */
.mod--articleNumber
    margin 0px 0 12px

.mod--articleNumber__list__item
    border-right 2px solid #ccc
    display inline-block
    line-height 1
    margin-right 0.2em
    padding-right 0.4em
    padding-bottom 2px
    &:last-child
        border-right 0

.mod--articleNumber__list__item__number
    color #b50000
