@charset "UTF-8"

@import "./modules/h5bp"

@import "./modules/normalize"
@import "./modules/variables"
@import "./modules/mixins"

@import "./modules/_header"
@import "./modules/_global-navigation"
@import "./modules/_contents"
@import "./modules/_menu"
@import "./modules/_link"
@import "./modules/_text"
@import "./modules/_button"
@import "./modules/_images"
@import "./modules/_form"
@import "./modules/_coupon"
@import "./modules/_footer"
@import "./modules/_sidebar"
@import "./modules/_googlemap"
@import "./modules/_pagetop"
@import "./modules/_checkbox"

@import "./modules/_article"
@import "./modules/_category"
@import "./modules/_first"

@import "./modules/_utilities"


@-ms-viewport {
    // Internet Explorer ( Windows store app )
    width: device-width;
}

body
    background #E0E0E0
    color #434343
    font-family fontDefault
    font-family 'Noto Sans Japanese', sans-serif
    font-size 14px
    line-height 1.6
    -webkit-text-size-adjust none
    -webkit-font-smoothing subpixel-antialiased
    @media(max-width: 980px)
        font-size 14px

h1,h2,h3,h4,h5,p,ul,ol,li,dl,dt,dd
    margin 0

ul,ol
    padding 0

a
    color #1122cc
    text-decoration none
    &:hover
        color #1122cc
        text-decoration underline
    &:visited
        color #660099

/**
 *
 * Common Layout
 *
 **/
.container
    margin 0 auto
    width 980px
    @media(max-width: 980px)
        width 100%

#contents
    clearfix()
    padding 30px 0 0
    &.fixed
        padding 85px 0 0
    @media(max-width: 980px)
        padding 10px 0 0


.loading
    display table
    position fixed
    top 0
    width 100%
    height 100%
    p
        display table-cell
        text-align center
        vertical-align middle

/**
 *
 * Example media query
 *
 **/
// .container
//     clearfix()
//     margin 0 auto
//     width 980px
    // @media(min-width: 500px) and (max-width: 767px)
    //     width 50%
    // @media(max-width: 767px)
    //     width 100%
    // @media(min-width: 768px)
    //     width 960px
    // @media(orientation: landscape)
    //     width 100%
