/**
 *
 *  Sidebar
 *
 **/
#side-contact
    &.fixed
        position fixed
        top 55px
        width 320px
        z-index 1
