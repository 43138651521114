/**
 *
 *  Coupon
 *
 **/
.mod-coupon__title
    background #b50000
    color #fff
    font-size 1.4em
    padding .3em .7em


.mod-coupon__body
    border 4px solid #b50000
    border-top 0
    padding 1em


.mod-coupon__body__item
    margin 0 0 2em
    &last-child
        margin 0


.mod-coupon__body__item__name
    font-size 1.2em


// @media (max-width $breakpoint)
//     .mod-coupon__title
//         font-size 1em
//     }
//     .mod-coupon__body__item__name
//         font-size 1em
//     }
// }

.mod-table__menu
    table
        margin 1em 0
        width 100%
    tr
        border 2px solid #c6c6c6
    tr.mod-table__add-text
        border-width 2px 2px 0 2px
    tr.mod-table__text
        border-top 2px dotted #c6c6c6
    th
        padding 0.5em 1em
        text-align left
    td
        padding 0.5em 1em
    .price
        text-align right
        white-space nowrap

.mod-table__information
    table
        margin 1em 0
        width 100%
    tr
        border 2px solid #c6c6c6
    th
        background #e0e0e0
        font-weight normal
        padding 0.5em 1em
        text-align left
        white-space nowrap
    td
        padding 0.5em 1em
        word-wrap break-word

.mod-table__time
    table
        margin 1em 0
        width 100%
    tr
        border 1px solid #c6c6c6
    th
        background #eee
        border 1px solid #c6c6c6
        font-weight normal
        padding 0.5em 1em
        text-align left
        vertical-align middle
    td
        border 1px solid #c6c6c6
        text-align center
        vertical-align middle
    thead
        th
            background #e0e0e0
            font-weight normal
            text-align center
            vertical-align middle
            &.first
                text-align left
    @media(max-width: 980px)
        th
            font-size 8px
        td
            font-size 8px
