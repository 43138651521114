/**
 *
 *  Global Navigation
 *
 **/
.menu__wrapper
    background #fff
    box-shadow 0px 3px 5px 0px rgba(0,0,0,0.75)
    // position fixed
    // top 96px
    width 100%
    z-index 12
    .container
        display table
    &.fixed
        position fixed
        top 0
    @media(max-width: 980px)
        position fixed
        top 0
        right -300px
        width 300px
        z-index 12

.global-navigation
    display table-cell
    @media(max-width: 980px)
        display block

.search
    display table-cell
    @media(max-width: 980px)
        display block

.global-navigation__list
    display table
    @media(max-width: 980px)
        display block

.global-navigation__item
    display table-cell
    text-align center
    white-space nowrap
    a
        border-bottom 2px solid #fff
        color #646464
        display block
        font-size 14px
        padding 1.2em .8em 1em
        text-decoration none
        &:hover
            border-bottom 2px solid $colorDefault
            color #646464
            text-decoration none
        &:visited
            color #646464
    i
        display none
    @media(max-width: 980px)
        display block
        text-align left
        a
            vertical-align middle
            position relative
            &:hover
                border-bottom 2px solid #fff
        i
            display inline
            position absolute
            right 2%
            top 50%
            margin-top -14px

.beauty
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorBeauty
.health
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorHealth
.gourmet
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorGourmet
.shopping
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorShopping
.lesson
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorLesson
.life
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorLife
.leisure
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorLeisure
.pet
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorPet
.service
    .global-navigation__item
        a
            &:hover
                border-bottom-color $colorService

.search
    font-size 14px
    text-align right
    [type=text]
        border-width 0 0 1px
        box-sizing border-box
        padding 0 22px 0 .2em
        width 160px
    input[type="submit"]
        -webkit-appearance none
    .fa
        font-size 16px
        position absolute
        top 2px
        right 0
    @media(max-width: 980px)
        padding 1.2em .8em 1em
        text-align left
        [type=text]
            border-radius 0
            width 100%

.search__wrapper
    position relative

.search__icon
    background none
    border none
    position absolute
    top 0
    right 0


.menu__wrapper
    &.open
        right 0
        transform translate(0, 0)
        transition 0.2s ease-out

.mask__modal
    &.open
        background-color rgba(0,0,0,0.5)
        position fixed
        top 0
        left 0
        width 100%
        height 100%
        z-index 11
