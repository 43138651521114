/**
 *
 *  Checkbox
 *
 **/
.mod--checkbox__icon
    display table
    font-size 22px
    margin 0 0 .5em
    dt
        display table-cell
        vertical-align top
        padding 2px 0 0
    dd
        display table-cell
    @media(max-width: 980px)
      font-size 18px
      dt
          padding-top 1px

.mod--checkbox__icom--image
    background url('../images/common/check.png') no-repeat left top
    display inline-block
    width 25px
    height 25px
    margin 0 6px -0.1em 0
