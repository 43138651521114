/**
 *
 *  Pagetop
 *
 **/
.pagetop
    display none
    font-size 26px
    line-height 1
    position fixed
    bottom 30px
    right 30px
    z-index 5
    a
        background rgba(255,255,255,0.7)
        border 2px solid #ccc
        border-radius 2px
        color #646464
        display block
        padding 2px 0 0
        text-align center
        width 36px
        height 36px - 2px
        &:hover
            color #646464
