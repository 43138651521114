/**
 *
 *  Text
 *
 **/
.text--red
    color $colorRed

.text--gray
    color $colorGray

.text--attention
    background $colorYellow
    font-weight 700
    line-height 1.8
    margin 0 2px
    padding 0 .3em 1px



/* size */
.text--large
    font-size 18px
    @media(max-width: 980px)
      font-size 14px

.text--huge
    font-size 20px
    @media(max-width: 980px)
      font-size 16px

.text--small
    font-size 10px
    @media(max-width: 980px)
      font-size 6px

.text--bold
    font-weight 700


/* layout */
.text--right
    text-align right

