/**
 *
 *  Main Layout
 *
 **/
#main
    float left
    width (( 640 / 980 ) * 100)%
    @media(max-width: 980px)
        float none
        width 100%

#sidebar
    float right
    position relative
    width (( 320 / 980 ) * 100)%
    @media(max-width: 980px)
        float none
        width 100%

/**
 *
 *  Module
 *
 **/
.module
    background #fff
    border-top 4px solid $colorDefault
    box-shadow 0px 3px 5px 0px rgba(0,0,0,0.75)
    margin 0 0 30px
    iframe
        width 100% !important

.beauty
    .module
        border-top-color $colorBeauty
.health
    .module
        border-top-color $colorHealth
.gourmet
    .module
        border-top-color $colorGourmet
.shopping
    .module
        border-top-color $colorShopping
.lesson
    .module
        border-top-color $colorLesson
.life
    .module
        border-top-color $colorLife
.leisure
    .module
        border-top-color $colorLeisure
.pet
    .module
        border-top-color $colorPet
.service
    .module
        border-top-color $colorService

.module__title
    border-bottom 2px solid $colorDefault
    font-size 24px
    font-weight 700
    padding .3em
    @media(max-width: 980px)
      font-size 18px

.beauty
    .module__title
        border-bottom-color $colorBeauty
.health
    .module__title
        border-bottom-color $colorHealth
.gourmet
    .module__title
        border-bottom-color $colorGourmet
.shopping
    .module__title
        border-bottom-color $colorShopping
.lesson
    .module__title
        border-bottom-color $colorLesson
.life
    .module__title
        border-bottom-color $colorLife
.leisure
    .module__title
        border-bottom-color $colorLeisure
.pet
    .module__title
        border-bottom-color $colorPet
.service
    .module__title
        border-bottom-color $colorService

.module__inner
    padding 1em
    // img
    //     max-width 100%

.pages__inner
    img
        max-width 100%

.module__headline
    background #f5f5f5
    border-left 5px solid $colorDefault
    font-size 18px
    font-weight 700
    padding .5em
    @media(max-width: 980px)
      font-size 14px

.beauty
    .module__headline
        border-left-color $colorBeauty
.health
    .module__headline
        border-left-color $colorHealth
.gourmet
    .module__headline
        border-left-color $colorGourmet
.shopping
    .module__headline
        border-left-color $colorShopping
.lesson
    .module__headline
        border-left-color $colorLesson
.life
    .module__headline
        border-left-color $colorLife
.leisure
    .module__headline
        border-left-color $colorLeisure
.pet
    .module__headline
        border-left-color $colorPet
.service
    .module__headline
        border-left-color $colorService

.module__headline--circle
    background url("http://cdn.notices.jp/lekumo/2015/images/common/icon_circle_big.png") no-repeat left .25em
    font-size 18px
    font-weight 700
    padding 0 0 0 1.5em
    @media(max-width: 980px)
        background-size 14px
        font-size 14px


/**
 *
 * Articles Module Title
 *
 **/
.module__titleArea
    border-bottom 2px solid $colorDefault
    padding 14px

.module__title__name
    font-size 24px
    font-weight 700
    @media(max-width: 980px)
        font-size 18px

.beauty
    .module__titleArea
        border-bottom-color $colorBeauty
.health
    .module__titleArea
        border-bottom-color $colorHealth
.gourmet
    .module__titleArea
        border-bottom-color $colorGourmet
.shopping
    .module__titleArea
        border-bottom-color $colorShopping
.lesson
    .module__titleArea
        border-bottom-color $colorLesson
.life
    .module__titleArea
        border-bottom-color $colorLife
.leisure
    .module__titleArea
        border-bottom-color $colorLeisure
.pet
    .module__titleArea
        border-bottom-color $colorPet
.service
    .module__titleArea
        border-bottom-color $colorService
