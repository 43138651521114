/**
 *
 *  Footer
 *
 **/
.footer__wrapper
    background $colorDefault
    color #fff
    padding 2em 0 80px
    position relative
    z-index 2
    a
      color #fff
      &:hover
      &:visited
        color #fff

.beauty
    .footer__wrapper
        background-color $colorBeauty
.health
    .footer__wrapper
        background-color $colorHealth
.gourmet
    .footer__wrapper
        background-color $colorGourmet
.shopping
    .footer__wrapper
        background-color $colorShopping
.lesson
    .footer__wrapper
        background-color $colorLesson
.life
    .footer__wrapper
        background-color $colorLife
.leisure
    .footer__wrapper
        background-color $colorLeisure
.pet
    .footer__wrapper
        background-color $colorPet
.service
    .footer__wrapper
        background-color $colorService

.footer__name
    font-size 15px
    font-weight 700
    @media(max-width: 980px)
        padding 0 1em

.footer__inner
    clearfix()
    @media(max-width: 980px)
        padding 0 1em

.footer__navigation
    float left
    font-size 12px
    @media(max-width: 980px)
        float none

.footer__navigation__list__item
    display inline-block
    margin .8em 2em .5em 0
    a
        color #fff
        &:hover
            color #fff
    @media(max-width: 980px)
        display block

.footer__sns
    float right
    @media(max-width: 980px)
        float none

.footer__sns__list__item
    display inline-block
    font-size 20px
    margin 0 0 0 1em
    a
        color #fff
        &:hover
            color #fff
    @media(max-width: 980px)
        margin 0 1em 0 0

.sp__footer__contact
    display none
    @media(max-width: 980px)
        background-color #b50000
        color #fff
        display table
        opacity 0.9
        position fixed
        width 100%
        bottom 0
        z-index 3

.sp__footer__left
    box-sizing border-box
    border-right 1px solid #fff
    display table-cell
    width 50%

.sp__footer__right
    box-sizing border-box
    border-left 1px solid #fff
    display table-cell
    width 50%

.sp__footer__left__a
.sp__footer__right__a
    display table
    color #fff
    padding 5px 0
    width 100%
    &:hover
    &:visited
        color #fff
        text-decoration none

.sp__footer__left__icon
.sp__footer__left__text
.sp__footer__right__icon
.sp__footer__right__text
    box-sizing border-box
    display table-cell
    vertical-align middle
    width 75%

.sp__footer__left__icon
.sp__footer__right__icon
    font-size 24px
    text-align center
    width 25%

.sp__footer__left__text
.sp__footer__right__text
    font-size 14px
    text-align left
