/**
 *
 *  Form
 *
 **/
.form-control
    box-sizing border-box

label
    line-height 1
    .required
        color $colorRed
        margin 0 0 0 1em

.optional
    color $colorGray
    margin 0 0 0 1em

.mod--form__time
    .form-control
        display inline-block
        width auto

.form-control[readonly]
    color #646464

.formBtn
    background-color #bf0000
    border none
    border-radius 5px
    color #fff
    display block
    font-size 15px
    margin 0 auto 20px
    padding 14px 0
    text-align center
    width 220px
    &:hover
        background-color #cd5c5c
        color #fff
        text-decoration none

.mod--form__submit
    text-align center

.mod--form__submit__inner
    margin 0 auto
    position relative
    width 220px
    height 50px
    .fa
        color #fff
        position absolute
        text-align left
        left 55px
        top 19px

